/* =================== LOADING =================== */

// Exibe loading
function showLoading() {
	$('.loading').fadeIn();
}

// Oculta loading
function hideLoading() {
	$('.loading').fadeOut();
}